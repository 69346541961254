.nav-menu {
    position: fixed;
    visibility: hidden;
    width: 100vw;
    /* height: 100vh; */
    height: 0;
    /* top: 0; */
    /* top: calc(0 + 100vh); */
    /* top: -100vh; */
    left: 0;
    right: 0;
    /* bottom: calc(100% + 100vh); */
    /* bottom: 100%; */
    bottom: 0;
    /* padding: 100px 5rem 2rem; */
    /* top: calc(0 + 100vh); */

    background: rgba(72, 82, 72, 0.553); /*TEST COLOR - DELETE LATER*/
    background: rgba(5, 21, 32, 0.985);
    /* display: none; */
    
    /* transform: translateY(0); */
    /* transform: translate3d(0, 0, -500px); */
    /* transform: cubic-bezier(.17,.67,.83,.67); */
    transition: all 0.5s cubic-bezier(.3,0,.5,1);
    z-index: 999;
}

.nav-menu--active {
    visibility: visible;
}

.nav-menu__logo {
    width: 150px;
    position: absolute;
    /* left: calc(2% + 16px); */
    left: 2%;
    /* top: 7.745px; */
    top: 17.725px;
    opacity: 0;
    z-index: 1000;
}

.nav-menu__btn-close {
    /* display: block; */
    width: 60px;
    height: 85px;
    position: absolute;
    top: -1px;
    right: calc(2% + 16px);
    /* right: 2%; */
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: 0.15em;
    text-align: center;
    z-index: 1000;
    /* opacity: 0.7; */
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    transition: opacity .15s linear, font-size .25s ease-out;
    opacity: 0;
}

.nav-menu__btn-close:hover {
    /* opacity: 1; */
    transition: all .175s linear;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__text {
    color: #FFFFFF;
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__line {
    background: #FFFFFF;
}

.nav-menu__btn-close:active,
.nav-menu__btn-close:focus { 
    border: none !important;
}

.nav-menu__btn-close__text {
    display: inline;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #7f8286;
    /* color: rgba(255, 255, 255, 1); */
}

.nav-menu__btn-close__line {
    position: relative;
    width: 28px;
    height: 3px;
    /* background: rgba(255, 255, 255, 1); */
    background: #7f8286;
    cursor: pointer;
    text-align: justify;
}

.nav-menu__btn-close__line:nth-of-type(1) {
    top: 13px;
    left: 16px;
    transform: rotate(45deg);
    transition: all .175s linear;
}

.nav-menu__btn-close__line:nth-of-type(2) {
    top: 10px;
    left: 16px;
    transform: rotate(-45deg);
    transition: all .175s linear;
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__line:nth-of-type(1) {
    transform: rotate(135deg);
    transition: all .175s linear;
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__line:nth-of-type(2) {
    transform: rotate(45deg);
    transition: all .175s linear;
}

.nav-menu__list {
    list-style: none;
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.nav-menu__title {
    text-transform: uppercase;
    font-size: 0.975rem;
    font-weight: 100;
    letter-spacing: 5px;
    color: #7f8286;
    text-align: center;
    cursor: default;
}

.nav-menu__link {
    color: #7f8286;
    font-size: 3.75rem;
    line-height: 4.625rem;
    font-weight: 800;
    letter-spacing: 0.01em;
    text-decoration: none;
    text-transform: none;
    transition: .25s ease-in-out;
}

.nav-menu__link:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: .25s ease-in-out;
}

.nav-menu__link--current {
    position: relative;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(rgba(34, 131, 196, 1), rgba(27, 27, 27, 1));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

/*image underlays*/
/* .nav-menu__link:nth-child(1):hover {
    background-image: url('../../assets/img/contact-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
} */

/* @media screen and (max-width: 425px) {
    .nav-menu__logo {
        width: 170px;
        top: 12.195px;
        transition: width .25s ease-out;
    }

    .nav-menu__btn-close {
        font-size: 0.75rem;
        transition: font-size .25s ease-out;
    }
} */

@media screen and (max-height: 550px) {
    .nav-menu__link {
        font-size: 2.75rem;
        line-height: 3.125rem;
    }
}

@media screen and (max-height: 425px) {
    .nav-menu__list {
        top: 55%;
    }
}