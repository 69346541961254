.footer {
    display: block;
    width: 100vw;
    height: 50px;
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /* margin-top: 2rem; */
}

.footer__logo {
    display: block;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    margin: 0 10px 0 0;
}

.footer__text {
    display: inline-block;
    position: absolute;
    left: 70px;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #878a8f;
    font-weight: 400;
    font-size: .8125rem;
    line-height: 1.25rem;
    letter-spacing: .03em;
    word-spacing: .275em;
}

.footer__icon {
    width: 11px;
    margin: 0 10px;
}

.footer__title,
.footer__title-vertical {
    display: inline;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    position: absolute;
    bottom: 0;
}

.footer__title {
    right: 60px;
    color: rgba(34, 131, 196, 0.95);
}

.footer__title-vertical {
    right: -70px;
    transform: rotate(-90deg) translateX(87px);
    color: rgba(10, 40, 61, 0.95);
}

@media screen and (max-width: 768px) {
    .footer__title,
    .footer__title-vertical {
        opacity: 0;
        transition: opacity .5s ease-in-out;
    }
}

@media screen and (max-width: 395px) {
    .footer {
        height: 70px;
    }
}

@media screen and (max-width: 320px) {
    .footer {
        height: 90px;
    }
}